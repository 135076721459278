// Row colour, when selected
$table-selected: $primary;

// Colour to use when shading
$table-shade: $black;

@function shade( $color, $percent ) {
	@return mix($table-shade, $color, $percent);
}

table.dataTable tbody {
	tr.selected,
	th.selected,
	td.selected {
		color: $white;

		a {
			color: #a2d4ed;
		}
	}
}

table.dataTable {
	// Body styles
	tbody > tr.selected,
	tbody > tr > .selected {
		background-color: $table-selected;
	}


	// Stripe classes - add "stripe" class to the table to activate
	&.stripe tbody,
	&.display tbody {
		> tr.odd.selected,
		> tr.odd > .selected {
			background-color: shade($table-selected, 2.35%);
		}
	}


	// Hover classes - add "hover" class to the table to activate
	&.hover tbody,
	&.display tbody {
		> tr.selected:hover,
		> tr > .selected:hover {
			background-color: shade($table-selected, 3.6%);
		}
	}


	// Sort column highlighting - add "order-column" class to the table to activate
	&.order-column tbody,
	&.display tbody {
		> tr.selected > .sorting_1,
		> tr.selected > .sorting_2,
		> tr.selected > .sorting_3,
		> tr > .selected {
			background-color: shade($table-selected, 2%);
		}
	}

	&.display tbody,
	&.order-column.stripe tbody {
		> tr.odd.selected {
			>.sorting_1 { background-color: shade($table-selected, 5.4%); }
			>.sorting_2 { background-color: shade($table-selected, 4.7%); }
			>.sorting_3 { background-color: shade($table-selected, 3.9%); }
		}

		> tr.even.selected {
			>.sorting_1 { background-color: shade($table-selected, 2%); }
			>.sorting_2 { background-color: shade($table-selected, 1.2%); }
			>.sorting_3 { background-color: shade($table-selected, 0.4%); }
		}

		> tr.odd > .selected { background-color: shade($table-selected, 5.4%); }
		> tr.even > .selected { background-color: shade($table-selected, 2%); }
	}

	&.display tbody,
	&.order-column.hover tbody {
		> tr.selected:hover {
			>.sorting_1 { background-color: shade($table-selected, 8.2%); }
			>.sorting_2 { background-color: shade($table-selected, 7.5%); }
			>.sorting_3 { background-color: shade($table-selected, 6.3%); }
		}

		> tr:hover > .selected,
		> tr > .selected:hover {
			background-color: shade($table-selected, 8.2%);
		}
	}

	tbody td.select-checkbox,
	tbody th.select-checkbox {
		position: relative;

		&:before,
		&:after {
			display: block;
			position: absolute;
			top: 1.2em; // Should be 50% but IE doesn't like it
			left: 50%;
			width: 12px;
			height: 12px;
			box-sizing: border-box;
		}

		&:before {
			content: ' ';
			margin-top: -6px;
			margin-left: -6px;
			border: 1px solid black;
			border-radius: 3px;
		}
	}

	tr.selected {
		td.select-checkbox,
		th.select-checkbox {
			&:after {
				content: '\2714';

				margin-top: -11px;
				margin-left: -4px;

				text-align: center;
				text-shadow:
					1px 1px #B0BED9,
					-1px -1px #B0BED9,
					1px -1px #B0BED9,
					-1px 1px #B0BED9;
			}
		}
	}
}

div.dataTables_wrapper {
	span.select-info,
	span.select-item {
		margin-left: 0.5em;
	}
}

@media screen and (max-width: 640px) {
	div.dataTables_wrapper {
		span.select-info,
		span.select-item {
			margin-left: 0;
			display: block;
		}
	}
}